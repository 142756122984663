<template>
    <div>
        <b-overlay :show="isSavingRentalPool">
            <b-card>
                <div class="head-create">
                    <h3>Crear Pool de Rentas</h3>
                    <b-button
                        :to="{ name: 'rental-pool'}"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    </b-button>
                </div>

                 <ValidationObserver ref="createRentalPool" v-slot="{ invalid }">
                    <b-form @submit.prevent="save">
                        <b-row>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="nombre">
                                    <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="text"
                                        v-model="name"
                                        :state="errors[0] ? false : valid ? true : null"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider name="resort" rules="required">
                                    <b-form-group label="Resort" slot-scope="{ valid, errors }">
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : null"
                                        v-model="resorts"
                                        @input="chooseCondo(resorts)"
                                    >
                                        <option value="">Seleccione Resort</option>
                                        <option
                                        v-for="hotel in hotels"
                                        :key="hotel.id"
                                        :value="hotel.id"
                                        >
                                        {{ hotel.name }}
                                        </option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider name="modalidad del Pool" rules="required">
                                    <b-form-group
                                    label="Modalidad del Pool"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : null"
                                        v-model="modelpoolId"
                                    >
                                        <option value="">Seleccione Modalidad</option>
                                        <option
                                        v-for="pool in modalidadPool"
                                        :key="pool.id"
                                        :value="pool.id"
                                        >
                                        {{ pool.name }}
                                        </option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="fechaIncio">
                                    <b-form-group
                                    label="Fecha Incio"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-input
                                        class="form-control"
                                        type="date"
                                        v-model="datein"
                                        :state="errors[0] ? false : valid ? true : null"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="fechaFinal">
                                    <b-form-group label="Fecha final" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="date"
                                        v-model="dateout"
                                        :state="errors[0] ? false : valid ? true : null"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <div class="float-left pt-2 mt-1">
                                    <b-form-checkbox
                                    v-model="status"
                                    :value="true"
                                    class="custom-control-success"
                                    >
                                    Activado
                                    </b-form-checkbox>
                                </div>
                            </b-col>
                        </b-row>

                        <div class="mt-1 mb-1 border rounded p-1">
                            <h4>Viviendas</h4> <br>
                            <b-row>
                                <b-col md="6">
                                    <b-form-select
                                        v-model="selectedCondo"
                                        :clearable="false"
                                        placeholder="Condo"
                                    >
                                        <option selected value="">Seleccione un Condominio</option>
                                        <option
                                            v-for="condo in condos"
                                            :key="condo.id"
                                            :value="condo.id"
                                        >
                                            {{condo.name}}
                                        </option>
                                    </b-form-select>
                                </b-col>
                                <b-col md="6">
                                    <b-form-select
                                        v-model="selectedSubCondo"
                                        :clearable="false"
                                        placeholder="Condo"
                                        @input="getHousing()"
                                    >
                                        <option selected value="">Seleccione un Subcondominio</option>
                                        <option
                                            v-for="subcondo in subcondos"
                                            :key="subcondo.id"
                                            :value="subcondo.id"
                                        >
                                            {{subcondo.name}}
                                        </option>
                                    </b-form-select>
                                </b-col>
                            </b-row>
                            <br>
                        </div>
                        <b-row class="mt-1 mb-1 border rounded p-1">
                            <b-col md="12">
                                <h4>Viviendas</h4>
                                <b-input
                                    v-model="filter"
                                    placeholder="Filtros para la tabla.."
                                ></b-input>
                                <hr />
                                <b-table
                                    responsive
                                    sticky-header="400px"
                                    :filter="filter"
                                    :items="optionsHousing"
                                    :fields="fields"
                                    class="mb-0"
                                    v-if="optionsHousing.length"
                                >
                                <!-- Optional default data cell scoped slot -->

                                <template #cell(status)="row">
                                    <div>
                                    <b-form-checkbox
                                        v-model="row.item.state"
                                        :value="true"
                                        switch
                                        inline
                                        unchecked-value="false"
                                        @change="optionSelecting(row.item, $event)"
                                        class="custom-control-success"
                                    >
                                    </b-form-checkbox>
                                    </div>
                                </template>
                                </b-table>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                            <div class="float-right">
                                <b-button
                                type="submit"
                                class="btn-block"
                                variant="primary"
                                :disabled="invalid || isSavingRentalPool"
                                >
                                <b-spinner small v-if="isSavingRentalPool" /> Guardar
                                </b-button>
                            </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>

            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex";

import { showAlertMessage } from "@/helpers/helpers";
import { modalidadPool } from "@/data/fivesClub";
import { createArraysToSaveCustomers } from "@/helpers/fivesClubHelper";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },
    components:{
        vSelect, flatPickr,
    },
    async created() {
        await this.getInitialData()
    },
    data() {
        return {
            modalidadPool,
            name: "",
            datein: "",
            dateout: "",
            status: true,
            optionsHousing: [],
            selectedHousing: '',
            housingList:[],
            modelpoolId: "",
            resorts: null,
            isSavingRentalPool: false,
            selectedCondo: null,
            selectedSubCondo: null,
            filter: null,
            fields: [
                { key: "status", label: "Agregar a lista", class: "text-center" },
                { key: "code", label: "Código", class: "text-center" },
                { key: "name", label: "Nombre", class: "text-center" },
                { key: "numberHousing", label: "Número de Vivienda", class: "text-center", },
            ],
        };
    },
    computed: {
        ...mapState('fivesClubCatalogs', [ 'condos', 'subCondos' ]),
        ...mapState('start', ['hotels']),
        ...mapState('auth', ['user']),
        subcondos(){
            const idCondoSelected = this.selectedCondo
            if(!idCondoSelected){
                this.selectedSubCondo =  null
            }
            return idCondoSelected ? this.subCondos.filter( c => c.condos === idCondoSelected) : []
        }
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['saveRentalPool', 'fetchGetRentalPool', 'fetchGetHousing','getInitialContentRentalPool' ]),
        ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos','setRentalPool']),
        ...mapMutations('start',['setHotels']),

        async getInitialData(){
            const { condos, subCondos,  hotels, rentalPool } = await this.getInitialContentRentalPool();
            this.setCondos(condos)
            this.setSubCondos(subCondos)
            this.setRentalPool(rentalPool)
            if(this.hotels.length === 0){
                this.setHotels(hotels)
            }

        },

        async save() {
            const housingList = createArraysToSaveCustomers(this.housingList);
            this.isSavingRentalPool = true;
            const payload = {
                idUser: this.user.idUser,
                modelpoolid: this.modelpoolId,
                name: this.name,
                datein: this.datein,
                dateout: this.dateout,
                status: Boolean(this.status),
                housing: this.housingList,
                resortid: this.resorts,
            }

            const { status, message } = await this.saveRentalPool(payload); // saving in backend

            if (status) {
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                Object.assign(this.$data, this.$options.data());
                this.$refs.createRentalPool.reset(); //reset form
                const listRentalPool = await this.fetchGetRentalPool()
                this.setRentalPool(listRentalPool);
            }

            this.isSavingRentalPool = false;
        },
        optionSelecting(option, e) {
            const bool = e == true ? true : false;
            if (bool == true) {
                this.housingList.push(option);
            } else {
                this.housingList = this.housingList.filter(
                (b) => b.id !== option.id
                );
            }
        },
        chooseCondo(resort){
            if(resort){
                const condo = this.condos.find(item => item.resorts === resort)
                this.selectedCondo = condo.id
                this.selectedSubCondo = null
            }
        },
        async getHousing(){
            if (this.selectedCondo && this.selectedSubCondo){
                const payload = {
                    IdCondo: this.selectedCondo,
                    IdSubCondo: this.selectedSubCondo,
                }
                const housing = await this.fetchGetHousing(payload)
                this.optionsHousing = housing
            }
        }
    },
};
</script>

<style scoped>
.head-create{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head-create h1{
    font-size: 1.5rem;
    color: #000;
}
</style>
<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
</style>